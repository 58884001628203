<template>
  <div class="card">

    <div class="card-header header-elements-inline bg-success-400" >
      <h5 class="card-title">MadeUp Information</h5>
      <div class="header-elements">
        <div class="list-icons">

          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Process:</label>
          <div class="col-md-10">
            <select class="form-control" v-if="madeup" v-model="madeup.process.code" required autofocus >
              <option v-for="process in processes" v-bind:value="process.code">
                {{ process.name }}
              </option>
            </select>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Article:</label>
          <div class="col-md-10">
            <select class="form-control" id="cmbarticle" v-if="madeup" v-model="madeup.article.code" >
              <option v-for="article in articles" v-bind:value="article.code">
                {{ article.name }}
              </option>
            </select>
          </div>
        </div>



        <div class="form-group row">
          <label class="col-md-2 col-form-label">Design:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="madeup" v-model="madeup.design.code">
              <option value="1">-</option>
              <option v-for="design in designs" v-bind:value="design.code">
                {{ design.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Color:</label>
          <div class="col-md-5">
            <select class="form-control" v-if="madeup" v-model="madeup.color.code">
              <option value="1">-</option>
              <option v-for="color in colors" v-bind:value="color.code">
                {{ color.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Quality:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="madeup" v-model="madeup.quality.code">
              <option value="1">-</option>
              <option v-for="quality in qualities" v-bind:value="quality.code">
                {{ quality.name }}
              </option>
            </select>
          </div>

          <label class="col-md-1 col-form-label">Style:</label>
          <div class="col-md-5">
            <select class="form-control" v-if="madeup" v-model="madeup.style.code">
              <option value="1">-</option>
              <option v-for="style in styles" v-bind:value="style.code">
                {{ style.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Size:</label>
          <div class="col-md-4">
            <select class="form-control" v-if="madeup" v-model="madeup.size.code">
              <option v-for="size in sizes" v-bind:value="size.code">
                {{ size.name }}
              </option>
            </select>
          </div>


          <label class="col-md-1 col-form-label">Weight:</label>
          <div class="col-md-2">
            <input type="number" class="form-control text-right" placeholder="Weight / Unit" min="0" v-if="madeup" v-model="madeup.weight">
          </div>

          <label class="col-md-1 col-form-label">Unit:</label>
          <div class="col-md-2">
            <select class="form-control" v-if="madeup" v-model="madeup.unit.code">
              <option value="1">Nos</option>
              <option value="5">Doz</option>
            </select>
          </div>


        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Print Name:</label>
          <div class="col-md-10">
            <input type="text" class="form-control" placeholder="Enter your print name here..." maxlength="100" v-if="madeup" v-model="madeup.p_name">
          </div>
        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'MadeUpForm',
    data () {
      return {
        madeup: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}'),
        processes:[],
        articles: [],
        designs: [],
        colors:[],
        qualities: [],
        styles: [],
        sizes:[]
      }
    },
    props: {
      mymadeup: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
      }
    },
    beforeMount () {
      if(this.mymadeup.code > 1){
        this.madeup = this.mymadeup; // save props data to itself's data and deal with tms
      }
    },
    component: {

    },
    created () {
      this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
    },
    mounted () {
      let self = this;
      //$('#cmbarticle').focus();

      self.loadProcesses();
      self.loadGroup();
      self.loadColors();
      self.loadDesigns();
      self.loadQualities();
      self.loadStyles();
      self.loadSizes();
      // this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeModal();
      },
      clear(){
        this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
      },
      loadAll(){
        this.loadProcesses();
        this.loadGroup();
        this.loadColors();
        this.loadDesigns();
        this.loadQualities();
        this.loadStyles();
        this.loadSizes();
      },
      loadProcesses(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.processes =[];
        fetch(`${process.env.VUE_APP_ROOT_API}/v1/tms/process/nature/3/after/399/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != []){
              self.$data.processes = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadGroup(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.articles =[];
          fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/article/nature/13/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != []){
              self.$data.articles = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.colors = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.designs = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.qualities = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.styles = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            self.$data.sizes = data.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;

        self.$data.madeup.article.code = parseInt(self.$data.madeup.article.code);
        self.$data.madeup.design.code = parseInt(self.$data.madeup.design.code);
        self.$data.madeup.color.code = parseInt(self.$data.madeup.color.code);
        self.$data.madeup.quality.code = parseInt(self.$data.madeup.quality.code);
        self.$data.madeup.style.code = parseInt(self.$data.madeup.style.code);
        self.$data.madeup.unit.code = parseInt(self.$data.madeup.unit.code);
        self.$data.madeup.weight = parseFloat(self.$data.madeup.weight);


        if (self.$data.madeup.process.code < 1){
          alert("Invalid Process");
          return
        }else if(self.$data.madeup.article.code < 1){
          alert("Invalid Article");
          return
        }else if(self.$data.madeup.design.code < 1){
          alert("Invalid Design");
          return
        }else if(self.$data.madeup.color.code < 1){
          alert("Invalid Color");
          return
        }else if(self.$data.madeup.quality.code < 1){
          alert("Invalid Quality");
          return
        }else if(self.$data.madeup.style.code < 1){
          alert("Invalid Style");
          return
        }else if(self.$data.madeup.size.code < 1){
          alert("Invalid Size");
          return
        }

        const requestOptions = {
          method:  (self.$data.madeup.code == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.madeup)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/`,requestOptions).then(userService.handleResponse).then(function (data) {
          if(data.accepted) {
            swal ( { title: "Success!" ,  text: data.message, icon:  "success"} );
            self.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
            self.$parent.$parent.loadData();
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: data.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
