<template>
  <div class="card">
    <div class="card-header header-elements-inline bg-secondary">
      <h6 class="card-title"> {{madeup.name}} : Requirements</h6>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="reload" @click="loadAll"></a>
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <!--<ul class="nav nav-tabs nav-tabs-highlight">-->
        <!--<li class="nav-item"><a href="#highlighted-tab1" class="nav-link legitRipple active show" data-toggle="tab">Fabric</a></li>-->
      <!--</ul>-->

      <!--<div class="tab-content">-->

        <div class="tab-pane fade active show" id="highlighted-tab1" style="background-color: whitesmoke">
          <div class="table-responsive ">
            <table class="table table-bordered">
              <thead>
              <tr>
                <th>Process</th>
                <th>Article</th>
                <th>Design</th>
                <th>Color</th>
                <th>Quality</th>
                <th>Style</th>
                <th>Size</th>
                <th>Qty</th>
                <th>#</th>
              </tr>
              </thead>
              <tbody v-for="(f, index) in madeup.fabrics" >
                <tr style="padding: 0px;">
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f"  v-model="f.fabric.process.code" >
                      <option v-for="process in processes" v-bind:value="process.code">
                        {{ process.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f"  v-model="f.fabric.article.code">
                      <option v-for="group in groups" v-bind:value="group.code">
                        {{ group.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f" v-model="f.fabric.design.code">
                      <option value="1">-</option>
                      <option v-for="design in designs" v-bind:value="design.code">
                        {{ design.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f" v-model="f.fabric.color.code">
                      <option value="1">-</option>
                      <option v-for="color in colors" v-bind:value="color.code">
                        {{ color.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f" v-model="f.fabric.quality.code">
                      <option value="1">-</option>
                      <option v-for="quality in qualities" v-bind:value="quality.code">
                        {{ quality.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f" v-model="f.fabric.style.code">
                      <option value="1">-</option>
                      <option v-for="style in styles" v-bind:value="style.code">
                        {{ style.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <select class="form-control" v-if="f" v-model="f.fabric.size.code">
                      <option v-for="size in sizes" v-bind:value="size.code">
                        {{ size.name }}
                      </option>
                    </select>
                  </td>
                  <td style="padding: 0px;">
                    <input type="number" class="form-control text-right" placeholder="Qty required" min="0" v-model="f.qty">
                  </td>
                  <td style="padding: 0px;text-align: center;">
                    <button name="btnRemoveRow"   type="button" class="btn border-warning text-warning-600 btn-flat btn-icon btn-rounded legitRipple btnRemoveRow" @click="removeFabricRow(index)" tabindex="-1" ><i class="icon-eraser"></i></button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <td style="padding: 0px;">
                  <button type="button" class="btn border-success text-success-800 btn-flat btn-icon btn-rounded legitRipple" @click="addFabricRow">
                    <i class="icon-plus3"></i>
                  </button>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tfoot>
            </table>
          </div>
        </div>

      <!--</div>-->
    </div>
    <div class="card-footer" style="padding: 3px;padding-right: 18px;">
      <div class="text-right" >
        <button type="button" class="btn btn-outline-primary" @click="saveDocument" >Update<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'MadeupRequirementForm',
    data () {
      return {
        madeup: JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}'),
        fabrics: [],
        processes:[],
        groups:[],
        designs:[],
        colors:[],
        qualities:[],
        styles:[],
        sizes:[],
      }
    },
    props: {
      mymadeup: {
        type: Object,
        default: () => JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
      }
    },
    beforeMount () {
      let self = this;
      self.$data.madeup = this.mymadeup; // save props data to itself's data and deal with tms

      const requestOptions = {
        method: 'GET',
        mode:"cors",
        headers: userService.authHeader()
      };

      fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/fabricrequirement/${this.madeup.code}/`,requestOptions).then(userService.handleResponse).then(function (resp) {
        if(resp.accepted) {
          if( _.isArray(resp.data) ){

            resp.data.forEach(function (f) {
              var r = {};
              r.fabric = f.item;
              r.fabric.article = f.item.group;
              r.qty = f.qty;
              r.wgt = f.wgt;
              self.$data.madeup.fabrics.push(r);
            });


          }
        } else {
          swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
        }
      }).catch(function (err) {
        swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
      });

    },
    component: {

    },
    created () {
      // this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}')
    },
    mounted () {
      let self = this;

      // self.loadFabrics();
      self.loadAll();
    },
    methods: {
      closeWindow(){
        this.$parent.$parent.closeFabricReqModal();
      },
      clear(){
        this.$data.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
      },
      addFabricRow(){
        let raw = {"fabric":{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]},"qty":1,"weight":0};
        raw.fabric.design.code = this.$data.madeup.design.code;
        raw.fabric.color.code = this.$data.madeup.color.code;
        raw.fabric.quality.code = this.$data.madeup.quality.code;
        raw.fabric.style.code = this.$data.madeup.style.code;
        raw.fabric.size.code = this.$data.madeup.size.code;
        //this.$data.madeup.fabrics.push (JSON.parse('{"fabric":{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"reed":0,"pick":0,"warp":[],"weft":[]},"qty":1,"weight":0}'));
        this.$data.madeup.fabrics.push (raw);
      },
      removeFabricRow(idx){
        if (idx > -1) {
          this.$data.madeup.fabrics.splice(idx, 1);
        }
      },
      loadAll(){
        this.loadFabrics();
        this.loadProcesses();
        this.loadDesigns();
        this.loadColors();
        this.loadItemGroups();
        this.loadQualities();
        this.loadStyles();
        this.loadSizes();
      },
      loadFabrics(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };
        self.$data.fabrics = [];
        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/fabric/all/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            if(resp.data != [] ){
              self.$data.fabrics = resp.data;
            }
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadProcesses(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/process/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.processes = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadItemGroups(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/itemgroup/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.groups = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadColors(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/color/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.colors = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadDesigns(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/design/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.designs = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadQualities(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/quality/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.qualities = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });

      },
      loadStyles(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/style/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.styles = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      loadSizes(){
        let self = this;

        const requestOptions = {
          method: 'GET',
          mode:"cors",
          headers: userService.authHeader()
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/size/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            self.$data.sizes = resp.data;
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      },
      saveDocument(){
        let self = this;


        if(self.$data.madeup.code < 1){
          alert("Invalid MadeUp");
          return
        }

        self.madeup.fabrics.forEach(function (fab) {

          fab.fabric.design.code = parseInt(fab.fabric.design.code)
          fab.fabric.color.code = parseInt(fab.fabric.color.code)
          fab.fabric.quality.code = parseInt(fab.fabric.quality.code)
          fab.fabric.style.code = parseInt(fab.fabric.style.code)
          fab.weight =  parseFloat(fab.weight);
          fab.qty =  parseFloat(fab.qty);
        });


        const requestOptions = {
          method:  'PUT',
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.madeup)
        };

        fetch(`${process.env.VUE_APP_ROOT_API}v1/tms/madeup/fabricrequirement/`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.accepted) {
            swal ( { title: "Success!" ,  text: resp.message, icon:  "success"} );
            self.madeup = JSON.parse('{"id":"","code":0,"status":0,"type":0,"nature":0,"name":"","p_name":"","process":{"id":"","code":0,"name":"","nature":0,"p_name":"","sys_obj":false},"parent":0,"article":{"id":"","code":0,"type":0,"status":0,"nature":0,"name":"","p_name":"","hsn":"","gst_rate":0,"itc_rate":0,"main_unit":{"id":"","code":0,"name":"","digits":0},"conv_fact":0,"sub_unit":{"id":"","code":0,"name":"","digits":0}},"design":{"id":"","code":0,"name":"","p_name":""},"style":{"id":"","code":0,"name":"","p_name":""},"color":{"id":"","code":0,"status":0,"name":"","p_name":""},"quality":{"id":"","code":0,"name":"","p_name":""},"size":{"id":"","code":0,"nature":0,"name":"","p_name":"","width":0,"length":0,"height":0,"unit":0},"unit":{"id":"","code":0,"name":"","digits":0},"weight":0,"fabrics":[]}');
            self.closeWindow();
          } else {
            swal ( { title: "Oh noes!" ,  text: resp.message, icon:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), icon:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>

</style>
