var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"context-table",attrs:{"id":"context-menu"}},[_c('div',{staticClass:"dropdown-menu"},[_c('a',{staticClass:"dropdown-item",attrs:{"href":"#"},on:{"click":_vm.loadData}},[_c('i',{staticClass:"icon-reset",staticStyle:{"color":"darkgreen"}}),_vm._v("Refresh")]),_c('div',{staticClass:"dropdown-divider"}),_vm._m(2),_vm._m(3),_c('div',{staticClass:"dropdown-divider"}),_vm._m(4),_c('div',{staticClass:"dropdown-divider"}),_vm._m(5)])]),_c('ul',{staticClass:"fab-menu fab-menu-fixed fab-menu-bottom-right is_stuck",attrs:{"data-fab-toggle":"hover","z-index":"99"}},[_c('li',[_c('a',{staticClass:"fab-menu-btn btn bg-primary-600 btn-float rounded-round btn-icon",on:{"click":_vm.showModal}},[_c('i',{staticClass:"fab-icon-open icon-plus3"}),_c('i',{staticClass:"fab-icon-close icon-plus3"})])])]),_c('vmodal',{attrs:{"name":"madeup-window","transition":"nice-modal-fade","delay":100,"resizable":true,"width":"75%","height":"78%"},on:{"before-open":_vm.beforeOpen,"before-close":_vm.beforeClose}},[_c('MadeupForm',{attrs:{"mymadeup":_vm.madeup}})],1),_c('vmodal',{attrs:{"name":"process-window","transition":"nice-modal-fade","delay":100,"resizable":true,"width":"90%","height":"66%"}},[_c('MadeupProcessForm',{attrs:{"mymadeup":_vm.madeup}})],1),_c('vmodal',{attrs:{"name":"fabreq-window","transition":"nice-modal-fade","delay":100,"resizable":true,"width":"90%","height":"66%"}},[_c('ReqForm',{attrs:{"mymadeup":_vm.madeup}})],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed-table-toolbar",attrs:{"id":"toolbar"}},[_c('div',{staticClass:"float-left"},[_c('h4',{staticStyle:{"padding-left":"10px","padding-top":"10px","padding-bottom":"0px"}},[_c('span',{staticClass:"icon-list"}),_vm._v("  Madeup's List")])]),_c('div',{staticClass:"float-right search"},[_c('input',{staticClass:"form-control",attrs:{"id":"txtsearch","type":"text","placeholder":"Search","autocomplete":"off"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-bordered table-condensed table-columned",attrs:{"id":"madeup-table","data-search":"false","data-pagination":"true","data-show-refresh":"false","data-show-columns":"false","data-page-list":"[10, 25, 50, 100, ALL]","data-show-footer":"false","data-toggle":"context","data-target":".context-table"}},[_c('thead',[_c('tr',[_c('th',{attrs:{"data-field":"id","data-class":"d-none"}},[_vm._v("id")]),_c('th',{attrs:{"data-field":"code","data-width":"100"}},[_vm._v("Code")]),_c('th',{attrs:{"data-field":"process.name"}},[_vm._v("Process")]),_c('th',{attrs:{"data-field":"article.name"}},[_vm._v("Article")]),_c('th',{attrs:{"data-field":"design.name"}},[_vm._v("Design")]),_c('th',{attrs:{"data-field":"color.name"}},[_vm._v("Color")]),_c('th',{attrs:{"data-field":"quality.name"}},[_vm._v("Quality")]),_c('th',{attrs:{"data-field":"style.name"}},[_vm._v("Style")]),_c('th',{attrs:{"data-field":"size.name"}},[_vm._v("Size")]),_c('th',{attrs:{"data-field":"weight","data-align":"right","data-formatter":"weightFormat"}},[_vm._v("Weight")])])]),_c('tbody'),_c('tfoot')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-list3"}),_vm._v("Requirements")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-gear"}),_vm._v("Process List")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-pencil3"}),_vm._v("Modify")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"dropdown-item",attrs:{"href":"#"}},[_c('i',{staticClass:"icon-blocked text-danger"}),_vm._v("Remove")])
}]

export { render, staticRenderFns }